import api from "!../../../../node_modules/.pnpm/style-loader@2.0.0_webpack@5.88.1/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.88.1/node_modules/css-loader/dist/cjs.js!../../../../node_modules/.pnpm/sass-loader@12.6.0_webpack@5.88.1/node_modules/sass-loader/dist/cjs.js!./styles.css";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};